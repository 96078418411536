import axios from 'axios'
import configService from '../ConfigService/configService'
import { IErrorResponse } from './types'

const http = axios.create({
  baseURL: configService.config.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const thunkHandler = async (asyncFn: Promise<any>, thunkAPI: any) => {
  try {
    const response = await asyncFn

    return response.data
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data) as IErrorResponse
  }
}

export { http, thunkHandler }
