import React from 'react'
import ReactDOM from 'react-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import './index.scss'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
// import enableTestingWithoutBackend from './Infrastructure/Testing/testWithoutBackend'
import keycloak, { initOptions } from './auth/keycloak'
import Loader from './Loader'
import configureInterceptors from './Infrastructure/Network/interceptors'


// uncomment to enable testing without backend
// const worker = setupWorker(...handlers)
// worker.start()

configureInterceptors()

// enableTestingWithoutBackend()
ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={<Loader />}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
