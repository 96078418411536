import React from 'react'
import { Link } from 'react-router-dom'

import styles from './NotFound.module.scss'

const NotFound = () => {
  return (
    <section className={styles.Container}>
      <h1>Sidan kunde inte hittas</h1>
      <Link to="/">Startsidan</Link>
    </section>
  )
}

export default NotFound
