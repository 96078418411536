import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { http, thunkHandler } from '../Infrastructure/Network/api'
import { handleErrorToast } from '../Infrastructure/Network/handleError'
import { IErrorResponse, RequestStatus } from '../Infrastructure/Network/types'
import { IOrder } from './IOrder'

export const orderAdapter = createEntityAdapter<IOrder>({
  selectId: (order) => order.orderId,
})
const initialState = orderAdapter.getInitialState<{
  status: RequestStatus
  error?: IErrorResponse
}>({
  status: 'idle',
})

export const fetchOrder = createAsyncThunk(
  'orders/fetch',
  async (orderId: string, thunkAPI) => {
    const response: any = await thunkHandler(
      http.get(`/v1/admin/orders/${orderId}`),
      thunkAPI
    )

    return response
  }
)

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    removeAllOrders: orderAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrder.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.status = 'succeeded'
        orderAdapter.removeAll(state)
        orderAdapter.addOne(state, action.payload)
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.status = 'failed'
        orderAdapter.removeAll(state)
        state.error = error
        handleErrorToast(error)
      })
  },
})

export default orderSlice.reducer

export const { removeAllOrders } = orderSlice.actions

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds,
} = orderAdapter.getSelectors((state: RootState) => state.orders)
