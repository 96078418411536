import { Button } from '@coopbetala/coop-digital-react-ui'
import { useKeycloak } from '@react-keycloak/web'
import React, { useCallback } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

const Login = () => {
  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  }

  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />
  }

  return (
    <div>
      <Button text="Logga in" variant="primary" onClick={login} />
    </div>
  )
}

export default Login
