import { toast } from '@coopbetala/coop-digital-react-ui'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { http, thunkHandler } from '../Infrastructure/Network/api'
import { handleErrorToast } from '../Infrastructure/Network/handleError'
import { IErrorResponse, RequestStatus } from '../Infrastructure/Network/types'
import { ICheckout } from './ICheckout'

const checkoutAdapter = createEntityAdapter<ICheckout>()
const initialState = checkoutAdapter.getInitialState<{
  status: RequestStatus
  error?: IErrorResponse
}>({
  status: 'idle',
})

export const fetchCheckout = createAsyncThunk(
  'checkouts',
  async (checkoutId: string, thunkAPI) => {
    const response: ICheckout = await thunkHandler(
      http.get(`/v1/checkout/${checkoutId}`),
      thunkAPI
    )

    return response
  }
)

export const fetchCheckoutDetails = createAsyncThunk(
  'checkouts/details',
  async (checkoutId: string, thunkAPI) => {
    const response: ICheckout = await thunkHandler(
      http.get(`/v1/checkout/${checkoutId}/details`),
      thunkAPI
    )

    if (response.hasOwnProperty('checkoutId')) {
      const newResponse = {
        ...response,
        id: (response as any).checkoutId as string,
      }
      delete (newResponse as any).checkoutId

      return newResponse
    }

    return response
  }
)

export const returnCheckout = createAsyncThunk(
  'checkouts/return',
  async (checkoutId: string, thunkAPI) => {
    const response: any = await thunkHandler(
      http.post(`/v1/checkout/${checkoutId}/return`),
      thunkAPI
    )

    return response
  }
)

const checkoutSlice = createSlice({
  name: 'checkouts',
  initialState,
  reducers: {
    removeAllCheckouts: checkoutAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckout.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchCheckout.fulfilled, (state, action) => {
        state.status = 'succeeded'
        checkoutAdapter.removeAll(state)
        checkoutAdapter.addOne(state, action.payload)
      })
      .addCase(fetchCheckout.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.status = 'failed'
        checkoutAdapter.removeAll(state)
        state.error = error
        handleErrorToast(error)
      })

    builder
      .addCase(fetchCheckoutDetails.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchCheckoutDetails.fulfilled, (state, action) => {
        state.status = 'succeeded'
        checkoutAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchCheckoutDetails.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.status = 'failed'
        state.error = error
        handleErrorToast(error)
      })

    builder
      .addCase(returnCheckout.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(returnCheckout.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const checkout = state.entities[action.meta.arg]!

        checkoutAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            returnOrderIds: [
              ...(checkout.returnOrderIds || []),
              action.payload.reversalOrderId,
            ],
          },
        })
        toast.success(`Köpet med id ${action.meta.arg} har returnerats`)
      })
      .addCase(returnCheckout.rejected, (state, action) => {
        state.status = 'failed'
        handleErrorToast(action.payload as IErrorResponse)
      })
  },
})

export default checkoutSlice.reducer

export const { removeAllCheckouts } = checkoutSlice.actions

export const {
  selectAll: selectAllCheckouts,
  selectById: selectCheckoutById,
  selectIds: selectCheckoutIds,
} = checkoutAdapter.getSelectors((state: RootState) => state.checkouts)
