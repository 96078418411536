import { Spinner } from '@coopbetala/coop-digital-react-ui'
import React from 'react'

import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader} data-testid="loader">
      <Spinner />
    </div>
  )
}

export default Loader
