import { toast } from '@coopbetala/coop-digital-react-ui'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '../app/store'
import { ICheckout } from '../Checkout/ICheckout'
import { http, thunkHandler } from '../Infrastructure/Network/api'
import { handleErrorToast } from '../Infrastructure/Network/handleError'
import { IErrorResponse, RequestStatus } from '../Infrastructure/Network/types'

export const purchasesAdapter = createEntityAdapter<ICheckout>()
const initialState = purchasesAdapter.getInitialState<{
  status: RequestStatus
  purchases: ICheckout[]
}>({
  status: 'idle',
  purchases: [],
})

export const fetchInvoicePurchases = createAsyncThunk(
  'purchases/fetchInvoicePurchases',
  async (_, thunkAPI) => {
    const response: any = await thunkHandler(
      http.get(`/v1/invoice-purchases`, {
        params: { pageSize: 1000 },
      }),
      thunkAPI
    )

    return response.checkouts
  }
)

export const fetchInvoicePurchase = createAsyncThunk(
  'purchases/fetchInvoicePurchase',
  async (checkoutId: string, thunkAPI) => {
    return thunkHandler(http.get(`/v1/checkout/${checkoutId}`), thunkAPI)
  }
)

export const confirmCheckout = createAsyncThunk(
  'purchases/confirm',
  async (checkoutId: string, thunkAPI) => {
    const response: any = await thunkHandler(
      http.post(`/v1/checkout/${checkoutId}/confirm`),
      thunkAPI
    )

    return response
  }
)

export const deleteCheckout = createAsyncThunk(
  'purchases/delete',
  async (checkoutId: string, thunkAPI) => {
    const response: any = await thunkHandler(
      http.delete(`/v1/checkout/${checkoutId}`),
      thunkAPI
    )

    return response
  }
)

const invoicePurchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    removeAllPurchases: purchasesAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicePurchases.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchInvoicePurchases.fulfilled, (state, action) => {
        state.status = 'succeeded'
        purchasesAdapter.removeAll(state)

        const sortedInvoicePurchases = (action.payload as ICheckout[])
          .slice()
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )

        purchasesAdapter.addMany(state, sortedInvoicePurchases)
      })
      .addCase(fetchInvoicePurchases.rejected, (state, action) => {
        state.status = 'failed'
        handleErrorToast(action.payload as IErrorResponse)
      })

    builder
      .addCase(fetchInvoicePurchase.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchInvoicePurchase.fulfilled, (state, action) => {
        state.status = 'succeeded'
        purchasesAdapter.removeAll(state)
        purchasesAdapter.addOne(state, action.payload)
      })
      .addCase(fetchInvoicePurchase.rejected, (state, action) => {
        state.status = 'failed'
        handleErrorToast(action.payload as IErrorResponse)
      })

    builder
      .addCase(confirmCheckout.pending, (state, action) => {
        purchasesAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: { pendingConfirmation: true },
        })
      })
      .addCase(confirmCheckout.fulfilled, (state, action) => {
        purchasesAdapter.removeOne(state, action.payload.checkoutId)
        toast.success(`Köpet med id: ${action.payload.checkoutId} har godkänts`)
      })
      .addCase(confirmCheckout.rejected, (state, action) => {
        const checkoutId = action.meta.arg
        purchasesAdapter.updateOne(state, {
          id: checkoutId,
          changes: { pendingConfirmation: false },
        })
        handleErrorToast(action.payload as IErrorResponse)
      })

    builder
      .addCase(deleteCheckout.pending, (state, action) => {
        purchasesAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: { pendingDelete: true },
        })
      })
      .addCase(deleteCheckout.fulfilled, (state, action) => {
        purchasesAdapter.removeOne(state, action.payload.checkoutId)
        toast.success(
          `Köpet med id: ${action.payload.checkoutId} har makulerats`
        )
      })
      .addCase(deleteCheckout.rejected, (state, action) => {
        const checkoutId = action.meta.arg
        purchasesAdapter.updateOne(state, {
          id: checkoutId,
          changes: { pendingDelete: false },
        })
        handleErrorToast(action.payload as IErrorResponse)
      })
  },
})

export default invoicePurchasesSlice.reducer

export const { removeAllPurchases } = invoicePurchasesSlice.actions

export const {
  selectAll: selectAllPurchases,
  selectById: selectPurchaseById,
  selectIds: selectPurchasesId,
} = purchasesAdapter.getSelectors((state: RootState) => state.purchases)
