import { combineReducers } from '@reduxjs/toolkit'
import giftCardsSlice from '../GiftCard/GiftCards.slice'
import purchasesSlice from '../Purchases/Purchases.slice'
import checkoutSlice from '../Checkout/Checkout.slice'
import orderSlice from '../Order/Order.slice'
import resendReceiptsSlice from '../Checkout/ResendReceipt/ResendReceipt.slice'

const rootReducer = () =>
  combineReducers({
    purchases: purchasesSlice,
    giftCards: giftCardsSlice,
    checkouts: checkoutSlice,
    orders: orderSlice,
    receipts: resendReceiptsSlice
  })

export default rootReducer
