import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import { ROUTES } from '../App'

interface SecureRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

const SecureRoute = ({ component: Component, ...rest }: SecureRouteProps) => {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default SecureRoute
