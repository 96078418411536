export const removeAllSpaces = (value: string) => value.replace(/\s/g, '')

interface IFormatOptions {
  blockSize?: number
  separator?: string
}

export const formatStringInBlocks = (
  value: string,
  options?: IFormatOptions
) => {
  const { blockSize = 4, separator = ' ' } = options || {}

  const regex = new RegExp(`.{${blockSize}}(?=.)`, 'g')
  return removeAllSpaces(value).replace(regex, `$&${separator}`).toUpperCase()
}
