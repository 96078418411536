import { toast } from '@coopbetala/coop-digital-react-ui'
import {
  createAsyncThunk,
  createEntityAdapter, createSlice
} from '@reduxjs/toolkit'

import { http, thunkHandler } from '../../Infrastructure/Network/api'
import { handleErrorToast } from '../../Infrastructure/Network/handleError'
import { IErrorResponse, RequestStatus } from '../../Infrastructure/Network/types'

import { IResendGiftCardReceipt } from '../ICheckout'

interface RequestState {
  status: RequestStatus
  error?: IErrorResponse
  message?: string
}


const resendReceiptAdapter = createEntityAdapter<IResendGiftCardReceipt>({
    selectId: (receipt) => receipt.checkOutId
    })


const initialState = resendReceiptAdapter.getInitialState<RequestState>({
  status: 'idle'
})

export const resendReceipt = createAsyncThunk(
  'returnReceipt',
  async (receipt: IResendGiftCardReceipt, thunkAPI) => {
    const response: any = await thunkHandler(
      http.post(
        `v1/checkout/${receipt.checkOutId}/resend-receipt`,
       { emailAddress: receipt.emailAddress}
      ),
      thunkAPI
    )
    return response
  }
)

const resendReceiptsSlice = createSlice({
  name: 'Receipts',
  initialState,
  reducers: {
    removeAllGiftCards: resendReceiptAdapter.removeAll
  },
  extraReducers: (builder) => {
     builder
       .addCase(resendReceipt.pending, (state, action) => {
         state.status = 'pending'
       })
       .addCase(resendReceipt.fulfilled, (state, action) => {
         const { checkOutId, emailAddress } = action.meta.arg
         state.status = 'succeeded'
         state.message = `Kvitto med 
           kvittonummer ${checkOutId} har skickats till ${emailAddress}`
         state.error = undefined
       
         toast.success(state.message)
       })
       .addCase(resendReceipt.rejected, (state, action) => {
         const error = action.payload as IErrorResponse
         state.status = 'failed'
         state.error = error
         handleErrorToast(error)
       })
  }
})

export default resendReceiptsSlice.reducer




