import React from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../App'

import styles from './Navigation.module.scss'

interface NavigationProps {
  className?: string
}

const Navigation = ({ className }: NavigationProps) => {
  return (
    <nav className={[styles.Nav, className].join(' ')} aria-label="primär">
      <ul>
        <li>
          <NavLink to={ROUTES.invoicePurchases} className={styles.link}>
            Fakturaköp
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.checkout} className={styles.link}>
            Hantera köp
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.giftCard} className={styles.link}>
            Hantera presentkort
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.order} className={styles.link}>
            Sök order
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
