import { Button, Toaster } from '@coopbetala/coop-digital-react-ui'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom'
import styles from './App.module.scss'
import Login from './Login'
import Navigation from './Navigation'
import SecureRoute from './SecureRoute'
import Loader from './Loader'
import NotFound from './NotFound'

const Start = lazy(() => import('./Start'))
const Purchases = lazy(() => import('./Purchases'))

const CheckoutSearch = lazy(() => import('./CheckoutSearch'))
const Checkout = lazy(() => import('./Checkout'))

const GiftCardSearch = lazy(() => import('./GiftCardSearch'))
const GiftCard = lazy(() => import('./GiftCard'))

const OrderSearch = lazy(() => import('./OrderSearch'))
const Order = lazy(() => import('./Order'))

export const ROUTES = {
  invoicePurchases: '/fakturakop',
  checkout: '/kop',
  giftCard: '/presentkort',
  order: '/order',
  login: '/login',
}

function App() {
  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (keycloak) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600)
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {}
    }
  }, [keycloak])

  return (
    <div className={styles.App}>
      <Suspense fallback={<Loader />}>
        <Router>
          <header className={styles.AppHeader}>
            <div className={styles.AppHeaderContainer}>
              <Link to="/" className={styles.AppBrand}>
                Presentkortsportalen
              </Link>
              <Navigation />
              {!!keycloak.authenticated && (
                <Button
                  text="Logga ut"
                  variant="invertedGreen"
                  onClick={() => keycloak.logout()}
                  size="small"
                />
              )}
            </div>
          </header>
          <main className={styles.AppContainer}>
            <Switch>
              <SecureRoute path="/" exact component={Start} />
              <SecureRoute
                path={ROUTES.invoicePurchases}
                component={Purchases}
              />
              <SecureRoute
                path={ROUTES.checkout}
                exact
                component={CheckoutSearch}
              />
              <SecureRoute
                path={`${ROUTES.checkout}/:id`}
                component={Checkout}
              />
              <SecureRoute
                path={`${ROUTES.giftCard}`}
                exact
                component={GiftCardSearch}
              />
              <SecureRoute
                path={`${ROUTES.giftCard}/:id`}
                component={GiftCard}
              />
              <SecureRoute
                path={`${ROUTES.order}`}
                exact
                component={OrderSearch}
              />
              <SecureRoute path={`${ROUTES.order}/:id`} component={Order} />
              <Route path={ROUTES.login} component={Login} />
              <Route component={NotFound} />
            </Switch>
          </main>
        </Router>
      </Suspense>
      <Toaster />
    </div>
  )
}

export default App
