import keycloak from '../../auth/keycloak'
import { http } from './api'

const configureInterceptors = () => {
  http.interceptors.request.use(
    (config) => {
      const token = keycloak.token
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default configureInterceptors
