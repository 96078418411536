import { toast } from '@coopbetala/coop-digital-react-ui'
import { IErrorResponse } from './types'

export const handleErrorToast = (error: IErrorResponse) => {
  let errorMessage = error?.errorDescription
  if (!error || (error as any)?.status === 500) {
    errorMessage = `Ett oväntat fel inträffade. Försök igen senare.`
  }

  toast.error(errorMessage)
}
